function Resume() {
  return (
    <div id="content-container">
      <div id="content-header">
        <h1>Resume</h1>
      </div>

      <div id="content-text">
        <object data="./resume_henry_nguyen.pdf" type="application/pdf" id="content-pdf" aria-label="Alternative Text"></object>
      </div>
    </div>
  );
}
export default Resume;
